<template>
  <div class="container">
    <div class="adv" v-if="advShow">
      <img :src="oss+'1670409387902.gif'"/>
    </div>

    <div class="float_right">
      <p class="img"><a @click="()=>{
            contentHtml = detail.rule
            contentHtmlModal = true
          }"><img :src="oss+'c0276112-595aee67-1675062381118.png'"/></a></p>
      <p class="img"><a @click="goService(detail.kefu_url)"><img :src="oss+'90eb0739-b9f9f865-1675062672397.png'"/></a></p>
      <!--<p class="img"><router-link to="/refund" v-if="is_open_refund"><img :src="oss+'pay_video_icon_3.png'"/></router-link></p>-->
    </div>

    <div class="header">
      <img :src="oss+'2023030801.jpg'" width="100%"/>
    </div>

    <div class="info">
      <div class="price">
        <label>¥<em>{{detail.money | keepTwoNum}}</em></label>
        <van-tag round type="primary" color="#eb333b" text-color="#fff" size="medium">下单智能降噪真无线蓝牙耳机+抽手机</van-tag>
      </div>
      <div class="tips">新用户专属福利</div>
      <div class="title">{{detail.title}}</div>
    </div>

    <div class="parameter">
      <div class="item">
        <div class="icon"><img :src="oss+'pay_video_icon_10.png'"/></div>
        <div class="t1">超大屏</div>
        <div class="t2">6.1英寸</div>
      </div>
      <div class="item">
        <div class="icon"><img :src="oss+'pay_video_icon_11.png'"/></div>
        <div class="t1">无需合约版</div>
        <div class="t2">无需合约版</div>
      </div>
      <div class="item">
        <div class="icon"><img :src="oss+'pay_video_icon_12.png'"/></div>
        <div class="t1">128GB 256GB</div>
        <div class="t2">128GB 256GB</div>
      </div>
      <div class="item">
        <div class="icon"><img :src="oss+'pay_video_icon_13.png'"/></div>
        <div class="t1">普通厚</div>
        <div class="t2">7.85m</div>
      </div>
    </div>

    <div class="btn" @click="onsubmit"><img :src="oss+'pay_video_btn.png'"/></div>

    <div class="payList" v-if="payList.length">
      <van-radio-group v-model="json.payWay">
        <van-cell-group :border="false">
          <van-cell v-for="item in payList" :key="item.key">
            <template #icon><img :src="item.img" height="20"/></template>
            <template #title>
              {{item.name}}
            </template>
            <template #right-icon>
              <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#ff3800" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>


    <van-popup v-model="paySuccessModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="PayStatus">
    <div class="success" v-if="paysuccess==1">
      <div><van-icon name="checked" size="100" color="#07c160"/></div>
      <div class="h4">支付成功</div>
      <div class="mt10 count-down">
        <van-count-down :time="paytime" @finish="finishPay">
          <template #default="timeData">
            <span class="block">{{ timeData.seconds }}</span>
            <span>S 后跳转</span>
          </template>
        </van-count-down>
      </div>
    </div>
    <div class="fail" v-if="paysuccess==0">
      <div class="h4">恭喜您</div>
      <div class="h5">获得<span>限时降价</span>资格</div>
      <div class="price"><label>¥<em>{{detail.sec_money | keepTwoNum}}</em></label></div>

      <div class="mt20"><van-button round type="primary" color="#428bf4" block @click="onSubmitPay(0)">立即抢购</van-button></div>
      <div class="mt20"><van-button round type="primary" color="#ff0000" block @click="onSubmitPay(1)">我已付款</van-button></div>
    </div>
  </van-popup>

  </div>

</template>
<script>
  import {keepTwoNum,getordernum} from '@/utils/utils'
  import {GetPayConfig,CreateOrder, GetOrderStatus} from './services/services'
  export default {
    components: {},
    data () {
      return {
        advShow:false,
        contentHtml:'',
        detail:{},
        payList:[],
        json:{
          money:'',
          type:'',
          payWay:0,
          order_sn:'',
          payAccount:'',
          url:location.href
        },
        paySuccessModal:false,
        create_status:0,
        paysuccess:0,
        paytime:3 * 1000,
        count:0,
        timer:null,
        contentHtmlModal:false,
        type:'',
        is_open_refund:false
      }
    },
    created(){
      // const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    filters:{
      keepTwoNum
    },
    computed:{
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }
      }
      if(this.json.order_sn != ''){
        setTimeout(()=>{
          this.findOrderStatus()
        },5000)
      }else{
        this.advShow = true
        setTimeout(()=>{
          this.advShow = false
        },6000)
      }
      this.findpayConfig()
    },
    methods:{
      goService(url){
        location.href=url
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            const {payConfig} = result.data.data
            this.detail = result.data.data


            this.payList=payConfig
            this.json = {
              ...this.json,
              payWay:payConfig[0].payWay,
              payAccount:payConfig[0].payAccount,
              money:this.detail.money
            }
            this.is_open_refund = result.data.data.is_open_refund

          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      onsubmit(){
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.payWay)
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        this.getCreateOrder({
          ...this.json,
          payAccount:pay.payAccount,
          order_sn:order_sn
        })
      },
      getCreateOrder(res){
        CreateOrder(res).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let payWay = res.payWay
            if(payWay==14){
              location.href=data
            }
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      findOrderStatus(){
        this.paySuccessModal=true
        GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = 1
            this.clearTime()
          }else{
            this.paysuccess = 0
            if(this.timer == null && this.count<5){
              this.getTimerStatus()
            }
          }
        })
      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=5){
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },3*1000)
      },
      finishPay(){
        this.$router.push({path:'/render/pay_success',query:{order_sn:this.json.order_sn,...this.$route.query}})
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.findOrderStatus()
            break;
          case 0:
            this.json.money = this.detail.sec_money
            this.getCreateOrder(this.json)
            break;
        }
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:#fff;
    .adv{position:absolute;width:100vw;height:100vh;left:0px;top:0px;z-index:999;
      img{width:100%;height:100%;}
    }
    .float_right{top:60px;
      p{padding:3px 0px;
        img{width:30px;}
      }
    }
    .header{
      img{width:100%;display:block;}
    }
    .info{padding:10px;
      .price{color:#eb5b27;display:flex;align-items:center;
        label{display:inline-block;}
        em{font-size:36px;font-weight:700;font-family:Arial;}
        span{height:20px;padding:0px 10px;}
      }
      .tips{color:#999;font-size:12px;margin-top:10px;}
      .title{margin-top:6px;font-size:16px;font-weight:700;}
    }
    .parameter{display:flex;margin-top:10px;
      .item{width:25%;text-align:center;
        .icon{
          img{width:40px;}
        }
        .t1,.t2{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;height:24px;line-height:24px;}
        .t1{}
        .t2{color:#999;font-size:12px;}
      }
    }
    .btn{width:86%;padding:20px 0px 2px;margin:auto;
      img{width:100%;}
    }
    .payList{}
  }
  .PayStatus{width:88%;border-radius:6px;
    .success{padding:30px;text-align:center;
      .h4{font-size:20px;font-weight:700;margin-top:10px;}
      .count-down{
        span{color:#999;}
      }
    }
    .fail{padding:20px 20px 30px 20px;text-align:center;
      .h4{font-size:24px;font-weight:700;}
      .h5{margin-top:10px;font-size:16px;color:#666;
        span{color:#ed5b42;}
      }
      .price{color:#ed5b42;margin-top:10px;
        em{font-size:40px;}
      }
      .old_price{font-size:12px;color:#999;text-decoration:line-through;}
    }
  }
</style>
